/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import avatar from "assets/img/faces/avatar.jpg";
import ak from "assets/img/ak_logo.png";

var ps;

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText={"Search in "}
            id="search"
            inputProps={{
              onChange: this.props.handleSearchChange,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>

        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      searchQuery: "",
      ...this.getCollapseStates(props.routes),
    };
  }

  mainPanel = React.createRef();

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.openCollapsesOnSearch(this.props.routes);
    });
  };

  filterRoutes = (routes) => {
    const { searchQuery } = this.state;

    if (!searchQuery) return routes;

    return routes.reduce((filtered, route) => {
      if (route.collapse && route.views) {
        const filteredViews = this.filterRoutes(route.views);

        if (filteredViews.length > 0) {
          filtered.unshift({
            ...route,
            views: filteredViews,
          });
        }
      } else if (route.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.unshift(route);
      }
      return filtered;
    }, []);
  };

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  openCollapse(collapse) {
    this.setState({ [collapse]: !this.state[collapse] });
  }

  openCollapsesOnSearch = (routes) => {
    const { searchQuery } = this.state;

    if (!searchQuery) return;

    routes.forEach((route) => {
      if (route.collapse && route.views) {
        const containsMatch = route.views.some((view) =>
          view.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (containsMatch) {
          this.setState({ [route.state]: true });
        } else {
          this.openCollapsesOnSearch(route.views);
        }
      }
    });
  };

  createLinks = (routes) => {
    const { classes, color, rtlActive } = this.props;
    const filteredRoutes = this.filterRoutes(routes);

    return filteredRoutes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const navLinkClasses =
          classes.itemLink +
          " " +
          classnames({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          classnames({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          classnames({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          classnames({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          classnames({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          classnames({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={classnames(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.openCollapse(prop.state);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={classnames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        classnames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        classnames({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        classnames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        classnames({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        classnames({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        classnames({
          [classes.itemIconRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={classnames({ [classes.item]: prop.icon !== undefined })}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={classnames(classes.itemLink, {
              [classes[color]]: this.activeRoute(prop.path),
            })}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={classes.itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={classes.itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={classnames(classes.itemText, {
                [classes.itemTextRTL]: rtlActive,
              })}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;
    const user = (
      <div className={classes.user}>
        <div className={classes.photo}>
          <img src={avatar} className={classes.avatarImg} alt="avatar" />
        </div>
      </div>
    );
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );
    const drawerPaperClasses = classnames({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    });
    const sidebarWrapperClasses = classnames({
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

    return (
      <Drawer
        anchor={rtlActive ? "right" : "left"}
        variant="permanent"
        classes={{
          paper: drawerPaperClasses + " " + classes[bgColor + "Background"],
        }}
        open
      >
        <div className={classes.logo}>
          <a
            href="/ "
            className={classnames(classes.logoLink, {
              [classes.logoLinkRTL]: rtlActive,
            })}
            target="_blank"
          >
            <div
              style={{ display: "flex", marginLeft: "10px" }}
              className={classes.logoImage}
            >
              <img
                style={{ width: "45px" }}
                src={ak}
                alt="logo"
                className={classes.img}
              />
              <h5
                style={{ fontWeight: "400", color: "black", marginLeft: "5px" }}
                className={classes.logoText}
              >
                AK CLASSES
              </h5>
            </div>
          </a>
        </div>
        <hr
          style={{
            marginTop: "0",
            marginBottom: "0",
            color: "black",
            width: "100%",
          }}
        />
        <div className={sidebarWrapperClasses}>
          <SidebarWrapper
            className={classes.sidebarWrapper}
            user={user}
            links={links}
            handleSearchChange={this.handleSearchChange}
          />
        </div>
        {image && (
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + image + ")" }}
          />
        )}
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  miniActive: PropTypes.bool,
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(sidebarStyle)(Sidebar);
