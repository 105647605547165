import {
  AddBoxRounded,
  CloudUploadRounded,
  DescriptionRounded,
  FolderOutlined,
  SubdirectoryArrowLeftRounded,
} from "@material-ui/icons";
import Assignment from "@material-ui/icons/Assignment";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import Constants from "../../variables/Constants";
import { Badge, List, ListItem, ListItemText } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function DocumentManager() {
  const [isAddCourseModalVisible, setAddCourseVisible] = useState(false);
  const [isAddSubjectModalVisible, setAddSubjectVisible] = useState(false);
  const [isAddChapterModalVisible, setAddChapterVisible] = useState(false);
  const [addType, setAddType] = useState(null); //folder or file
  const [allDocuments, setAllDocuments] = useState([]);
  const [showing, setShowing] = useState("course"); //course || subject || chapter
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(null);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [subjectTitle, setSubjectTitle] = useState("");
  const [subjectDescription, setSubjectDescription] = useState("");
  const [subjectFile, setSubjectFile] = useState(null);
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");
  const [chapterFile, setChapterFile] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const grid = 8;

  useEffect(() => {
    fetchAllCourses();
  }, []);

  const sortByFolderName = (array) => {
    return array.sort((a, b) => {
      const nameA = a.folder_name || "";
      const nameB = b.folder_name || "";
      if (nameA === "" && nameB === "") return 0;
      if (nameA === "") return 1;
      if (nameB === "") return -1;
      return nameA.localeCompare(nameB);
    });
  };

  const fetchAllCourses = async () => {
    const { data } = await axios.get(Constants.getUrls.getAllDocuments);
    let allDocuments = data.data;

    // allDocuments.forEach((course) => {
    //   course.subjectData = sortByFolderName(course.subjectData);
    //   course.subjectData.forEach((subject) => {
    //     if (subject.chapterDetails && subject.chapterDetails.length > 0) {
    //       subject.chapterDetails = sortByFolderName(subject.chapterDetails);
    //     }
    //   });
    // });

    setAllDocuments(allDocuments);
  };

  const createCourse = async () => {
    if (courseTitle.length === 0) {
      return alert("Title is required");
    }
    const payload = {
      title: courseTitle,
      description: courseDescription,
    };
    if (selectedCourse) {
      payload.id = selectedCourse.id;
    }

    const { data } = await axios.post(
      selectedCourse
        ? Constants.postUrls.updateCourse
        : Constants.postUrls.createCourse,
      payload
    );

    if (data.status === "success") {
      fetchAllCourses();
    }
    setCourseTitle("");
    setCourseDescription("");
    setAddCourseVisible(false);
  };

  const createSubject = async () => {
    if (subjectTitle.length === 0) {
      return alert("Title is required");
    }
    const fd = new FormData();
    fd.append("title", subjectTitle);
    fd.append("description", subjectDescription);
    if (addType === "file") {
      if (!subjectFile && !selectedSubject) {
        return alert("File is required");
      }
      fd.append("file", subjectFile);
    }
    if (selectedSubject) {
      fd.append("id", selectedSubject.id);
    }
    fd.append("type", addType);
    fd.append("course_document_id", allDocuments[selectedCourseIndex].id);

    const { data } = await axios.post(
      selectedSubject
        ? Constants.postUrls.updateSubject
        : Constants.postUrls.createSubject,
      fd
    );
    if (data.status === "success") {
      setSubjectDescription("");
      setSubjectTitle("");
      setSubjectFile(null);
      setAddType("");
      setAddSubjectVisible(false);
      fetchAllCourses();
    }
  };

  const createChapter = async () => {
    if (chapterTitle.length === 0) {
      return alert("Title is required");
    }
    const fd = new FormData();
    fd.append("title", chapterTitle);
    fd.append("description", chapterDescription);
    if (selectedChapter) {
      fd.append("id", selectedChapter.id);
    }
    if (addType === "file" && !selectedChapter) {
      if (!chapterFile) {
        return alert("File is required");
      }
      fd.append("file", chapterFile);
    }
    fd.append("type", addType);
    fd.append("course_document_id", allDocuments[selectedCourseIndex]?.id);
    fd.append(
      "subject_document_id",
      allDocuments[selectedCourseIndex]?.subjectData[selectedSubjectIndex]?.id
    );
    const { data } = await axios.post(
      selectedChapter
        ? Constants.postUrls.updateChapter
        : Constants.postUrls.createChapter,
      fd
    );
    if (data.status === "success") {
      setChapterDescription("");
      setChapterTitle("");
      setChapterFile(null);
      setAddType("");
      setAddChapterVisible(false);
      fetchAllCourses();
    }
  };

  const openRenameModal = () => {
    if (showing === "course") {
      setCourseTitle(selectedCourse.title);
      setCourseDescription(selectedCourse.description);
      setAddCourseVisible(true);
    }
    if (showing === "subject") {
      setSubjectTitle(selectedSubject.title);
      setSubjectDescription(selectedSubject.description);
      setAddSubjectVisible(true);
    }
    if (showing === "chapter") {
      setChapterTitle(selectedChapter.title);
      setChapterDescription(selectedChapter.description);
      setAddChapterVisible(true);
    }
  };

  const openDeleteModal = () => {
    console.log(selectedCourse);

    if (showing === "course") {
      setIsDeleteModalVisible(true);
    }
    if (showing === "subject") {
      setIsDeleteModalVisible(true);
    }
    if (showing === "chapter") {
      setIsDeleteModalVisible(true);
    }
  };

  useEffect(() => {
    if (isAddCourseModalVisible === false && isDeleteModalVisible === false) {
      setSelectedCourse(null);
    }
  }, [isAddCourseModalVisible, isDeleteModalVisible]);
  useEffect(() => {
    if (isAddSubjectModalVisible === false && isDeleteModalVisible === false) {
      setSelectedSubject(null);
    }
  }, [isAddSubjectModalVisible, isDeleteModalVisible]);
  useEffect(() => {
    if (isAddChapterModalVisible === false && isDeleteModalVisible === false) {
      setSelectedChapter(null);
    }
  }, [isAddChapterModalVisible, isDeleteModalVisible]);

  const deleteDocumens = async () => {
    console.log(selectedCourse, showing);

    console.log(
      showing === "course"
        ? selectedCourse
        : showing === "subject"
        ? selectedSubject
        : selectedChapter
    );

    const payload = {
      category: showing,
      id:
        showing === "course"
          ? selectedCourse.id
          : showing === "subject"
          ? selectedSubject.id
          : selectedChapter.id,
      type:
        showing === "course"
          ? "folder"
          : showing === "subject"
          ? selectedSubject.file_name
            ? "file"
            : "folder"
          : showing === "chapter" && "file",
    };
    const { data } = await axios.post(
      Constants.deleteUrls.deleteDocuments,
      payload
    );
    if (data.status === "success") {
      fetchAllCourses();
      setIsDeleteModalVisible(false);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    console.log(destinationIndex);

    const selectedSubject =
      allDocuments[selectedCourseIndex].subjectData[sourceIndex];
    console.log(selectedSubject);
    const payload = {
      order: destinationIndex + 1,
      id: selectedSubject.id,
    };
    const { data } = await axios.post(
      Constants.postUrls.updateSubject,
      payload
    );
    if (data.status === "success") {
      fetchAllCourses();
    }
  };
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#fff",
    display: "flex",
    overflow: "auto",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onChapterDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    console.log(destinationIndex);

    const selectedChapter =
      allDocuments[selectedCourseIndex].subjectData[selectedSubjectIndex]
        .chapterDetails[sourceIndex];
    console.log(selectedChapter);
    const payload = {
      order: destinationIndex + 1,
      id: selectedChapter.id,
    };
    const { data } = await axios.post(
      Constants.postUrls.updateChapter,
      payload
    );
    if (data.status === "success") {
      fetchAllCourses();
    }
  };

  return (
    <div
      onClick={() => {
        setClicked(false);
      }}
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  marginBottom: 32,
                }}
              >
                <div>
                  <h4>
                    /AK Library/
                    {allDocuments[selectedCourseIndex]?.title &&
                      allDocuments[selectedCourseIndex]?.title + "/"}
                    {allDocuments[selectedCourseIndex]?.subjectData[
                      selectedSubjectIndex
                    ]?.title &&
                      allDocuments[selectedCourseIndex]?.subjectData[
                        selectedSubjectIndex
                      ]?.title + "/"}
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  {(showing === "subject" || showing === "course") && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4,
                        marginRight: 20,
                        width: 200,
                        flexDirection: "column",
                        height: 60,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (showing === "course") {
                          setAddCourseVisible(true);
                        }
                        if (showing === "subject") {
                          setAddSubjectVisible(true);
                        }
                        setAddType("folder");
                      }}
                    >
                      <AddBoxRounded style={{ width: 60, height: 60 }} />
                      <h4>Create New Folder</h4>
                    </div>
                  )}
                  {(showing === "subject" || showing === "chapter") && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4,
                        marginRight: 20,
                        width: 100,
                        flexDirection: "column",
                        height: 60,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (showing === "subject") {
                          setAddSubjectVisible(true);
                        }
                        if (showing === "chapter") {
                          setAddChapterVisible(true);
                        }
                        setAddType("file");
                      }}
                    >
                      <CloudUploadRounded style={{ width: 60, height: 60 }} />
                      <h4>Upload File</h4>
                    </div>
                  )}
                </div>
              </div>
              {showing === "course" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {allDocuments.map((course, index) => (
                    <div
                      onClick={() => {
                        setSelectedCourseIndex(index);
                        setShowing("subject");
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setClicked(true);
                        setPoints({ x: e.pageX, y: e.pageY });
                        setSelectedCourse(course);
                        setAddType("folder");
                      }}
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        //   border: "1px solid #ddd",
                        borderRadius: 4,
                        marginRight: 20,
                        width: 160,
                        flexDirection: "column",
                        // height: 200,
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <FolderOutlined
                        style={{ width: 100, height: 100, color: "#3a519d" }}
                      />
                      <h4>{course.title}</h4>
                      {course.subjectData && (
                        <div
                          style={{ position: "absolute", top: 10, right: 36 }}
                        >
                          <Badge
                            color="error"
                            badgeContent={course.subjectData?.length}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {showing === "subject" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SubdirectoryArrowLeftRounded
                    style={{
                      width: 100,
                      height: 100,
                      color: "lightgray",
                    }}
                    onClick={() => {
                      setShowing("course");
                      setSelectedCourseIndex(null);
                    }}
                  />
                  <h4>...</h4>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            //   //   border: "1px solid #ddd",
                            //   borderRadius: 4,
                            //   marginRight: 20,
                            //   width: 160,
                            //   flexDirection: "column",
                            //   //   height: 200,
                            //   cursor: "pointer",
                            // }}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {allDocuments[
                              selectedCourseIndex
                            ]?.subjectData?.map((subject, index) => (
                              <Draggable
                                key={subject.id}
                                draggableId={subject.id + ""}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      // style={{
                                      //   display: "flex",
                                      //   alignItems: "center",
                                      //   justifyContent: "center",
                                      //   //   border: "1px solid #ddd",
                                      //   borderRadius: 4,
                                      //   marginRight: 20,
                                      //   width: 160,
                                      //   flexDirection: "column",
                                      //   position: "relative",
                                      //   cursor: "pointer",
                                      // }}
                                      onContextMenu={(e) => {
                                        e.preventDefault();
                                        console.log(e.pageX);
                                        setClicked(true);
                                        setPoints({ x: e.pageX, y: e.pageY });
                                        setSelectedSubject(subject);
                                        setAddType(
                                          subject.file_name ? "file" : "folder"
                                        );
                                      }}
                                      onClick={() => {
                                        if (subject.file_name) {
                                          window.open(
                                            Constants.imgDomain +
                                              "/attachments/" +
                                              allDocuments[selectedCourseIndex]
                                                ?.folder_name +
                                              "/" +
                                              subject.file_name
                                          );
                                        } else {
                                          setShowing("chapter");
                                          setSelectedSubjectIndex(index);
                                        }
                                      }}
                                    >
                                      {subject.file_name ? (
                                        <DescriptionRounded
                                          style={{
                                            width: 100,
                                            height: 100,
                                            color: "#09931e",
                                          }}
                                        />
                                      ) : (
                                        <FolderOutlined
                                          style={{
                                            width: 100,
                                            height: 100,
                                            color: "#3a519d",
                                          }}
                                        />
                                      )}
                                      <h4>
                                        {subject.file_name
                                          ? subject.file_name
                                          : subject.title}
                                      </h4>
                                      {subject.chapterDetails && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: 10,
                                            right: 36,
                                          }}
                                        >
                                          <Badge
                                            color="error"
                                            badgeContent={
                                              subject.chapterDetails?.length
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}

              {showing === "chapter" && (
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    // flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      marginRight: 20,
                      width: 160,
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowing("subject");
                      setSelectedSubjectIndex(null);
                    }}
                  > */}
                  <SubdirectoryArrowLeftRounded
                    onClick={() => {
                      setShowing("subject");
                      setSelectedSubjectIndex(null);
                    }}
                    style={{ width: 100, height: 100, color: "lightgray" }}
                  />
                  <h4>...</h4>
                  {/* </div> */}
                  <DragDropContext onDragEnd={onChapterDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            //   //   border: "1px solid #ddd",
                            //   borderRadius: 4,
                            //   marginRight: 20,
                            //   width: 160,
                            //   flexDirection: "column",
                            //   //   height: 200,
                            //   cursor: "pointer",
                            // }}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {allDocuments[selectedCourseIndex]?.subjectData[
                              selectedSubjectIndex
                            ]?.chapterDetails?.map((chapter, index) => (
                              <Draggable
                                key={chapter.id}
                                draggableId={chapter.id + ""}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      onContextMenu={(e) => {
                                        e.preventDefault();
                                        setClicked(true);
                                        setPoints({ x: e.pageX, y: e.pageY });
                                        setSelectedChapter(chapter);
                                        setAddType("file");
                                      }}
                                      onClick={() => {
                                        if (chapter.file_name) {
                                          window.open(
                                            Constants.imgDomain +
                                              "/attachments/" +
                                              allDocuments[selectedCourseIndex]
                                                ?.folder_name +
                                              "/" +
                                              allDocuments[selectedCourseIndex]
                                                ?.subjectData[
                                                selectedSubjectIndex
                                              ]?.folder_name +
                                              "/" +
                                              chapter.file_name
                                          );
                                        }
                                      }}
                                    >
                                      {chapter.file_name && (
                                        <DescriptionRounded
                                          style={{
                                            width: 100,
                                            height: 100,
                                            color: "#09931e",
                                          }}
                                        />
                                      )}
                                      <h4>
                                        {chapter.file_name && chapter.file_name}
                                      </h4>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <Modal
          visible={isAddCourseModalVisible}
          effect="fadeInUp"
          onClickAway={() => {
            setAddCourseVisible(false);
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <h4>{selectedCourse ? "Update " : "Create New "}Course</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Title"
                        inputProps={{
                          value: courseTitle,
                          required: true,
                          onChange: (e) => setCourseTitle(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Description"
                        inputProps={{
                          value: courseDescription,
                          required: true,
                          onChange: (e) => setCourseDescription(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={createCourse}
                      className=""
                      //   color="#3a519d"
                      type="submit"
                      style={{
                        background: "#3a519d",
                      }}
                    >
                      {selectedCourse ? "Update Folder" : "Create Folder"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        <Modal
          visible={isAddSubjectModalVisible}
          //   width="600"
          //   height="100"
          effect="fadeInUp"
          onClickAway={() => {
            setAddSubjectVisible(false);
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {addType === "folder" ? (
                    <h4>{selectedSubject ? "Update" : "Create New"} Folder</h4>
                  ) : selectedSubject ? (
                    <h4>Rename File</h4>
                  ) : (
                    <h4>Upload File</h4>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Title"
                        inputProps={{
                          required: true,
                          value: subjectTitle,
                          onChange: (e) => setSubjectTitle(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Description"
                        inputProps={{
                          value: subjectDescription,
                          required: true,
                          onChange: (e) =>
                            setSubjectDescription(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    {addType === "file" && !selectedSubject && (
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="File"
                          inputProps={{
                            type: "file",
                            required: true,
                            onChange: (e) => setSubjectFile(e.target.files[0]),
                            accept: ".docx, .pdf",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={createSubject}
                      className=""
                      //   color="#3a519d"
                      type="submit"
                      style={{
                        background: "#3a519d",
                      }}
                    >
                      {addType === "folder"
                        ? selectedSubject
                          ? "Update Folder"
                          : "Create Folder"
                        : selectedSubject
                        ? "Rename File"
                        : "Upload File"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        <Modal
          visible={isAddChapterModalVisible}
          //   width="600"
          //   height="100"
          effect="fadeInUp"
          onClickAway={() => {
            setAddChapterVisible(false);
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {addType === "folder" ? (
                    <h4>Create New Folder</h4>
                  ) : selectedChapter ? (
                    "Rename File"
                  ) : (
                    <h4>Upload File</h4>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Title"
                        inputProps={{
                          required: true,
                          value: chapterTitle,
                          onChange: (e) => setChapterTitle(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Description"
                        inputProps={{
                          value: chapterDescription,
                          required: true,
                          onChange: (e) =>
                            setChapterDescription(e.target.value),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    {addType === "file" && !selectedChapter && (
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="File"
                          inputProps={{
                            type: "file",
                            required: true,
                            onChange: (e) => setChapterFile(e.target.files[0]),
                            accept: ".docx, .pdf",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={createChapter}
                      className=""
                      color="#3a519d"
                      type="submit"
                      style={{
                        background: "#3a519d",
                      }}
                    >
                      {selectedChapter ? "Rename File" : "Upload File"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={isDeleteModalVisible}
          effect="fadeInUp"
          onClickAway={() => {
            setIsDeleteModalVisible(false);
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <h4>Delete File/Folder</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5>Are you sure you want to delete this file/folder?</h5>
                    </GridItem>
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => setIsDeleteModalVisible(false)}
                      className=""
                      color="#3a519d"
                      type="submit"
                      style={{
                        background: "grey",
                      }}
                    >
                      {"Cancel"}
                    </Button>
                    <Button
                      onClick={deleteDocumens}
                      className=""
                      color="#3a519d"
                      type="submit"
                      style={{
                        background: "red",
                      }}
                    >
                      {"Delete"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </GridContainer>

      {clicked && (
        <div
          style={{
            top: points.y,
            left: points.x,
            position: "fixed",
            background: "#fff",
            width: 150,
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          }}
        >
          <List>
            <ListItem button onClick={openRenameModal}>
              <ListItemText primary="Rename" />
            </ListItem>
            <ListItem button>
              <ListItemText onClick={openDeleteModal} primary="Delete" />
            </ListItem>
          </List>
        </div>
      )}
    </div>
  );
}

export default DocumentManager;
